import {Container, Box, Content} from './style.js';
import Woman from "../../assets/woman.png";
function WhoSection() {
  return (
    <Container id='who'>
        <Box>
            <h1>Quem Somos</h1>
            <p>Na Zaevo, somos mais do que especialistas em recrutamento
             e seleção de profissionais de tecnologia; somos impulsionadores
             de mudanças e facilitadores de crescimento. Com um compromisso 
             inabalável com a excelência e a inovação, nossa equipe está dedicada 
             a desburocratizar processos e entregar resultados rápidos e eficientes
              para empresas e talentos em todo o mundo.</p>
            <p>Entendemos que, no mundo acelerado da tecnologia, cada contratação 
            é uma oportunidade crucial para impulsionar o sucesso de nossos clientes.
            É por isso que combinamos expertise técnica com uma abordagem centrada nas
            pessoas para encontrar não apenas candidatos tecnicamente competentes, mas
            também aqueles que se encaixam perfeitamente na cultura e na visão de cada 
            empresa.</p>
        </Box>
        <Box>
            <img src={Woman} />
        </Box>
    </Container>
  );
}
export default WhoSection;

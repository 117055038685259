import {Container, Box} from './style.js';
function ServicesSection() {
  return (
    <Container id='services'>
        <Box>
          <p>Recrutamento especializado para todas as áreas de tecnologia</p>
        </Box>
        <Box>
          <p>Processo seletivo completo: desde a triagem inicial até a contratação final</p>
        </Box>
        <Box>
          <p>Consultoria personalizada para entender e atender às necessidades específicas do cliente</p>
        </Box>
    </Container>
  );
}
export default ServicesSection;

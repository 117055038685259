import {Img} from './style.js';
import WhatsApp from '../../assets/whatsapp.png';
function WhatsappButton() {
  return (
   <>
   <a href='https://api.whatsapp.com/send?phone=5585997664269&text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20servi%C3%A7o.' target='_blank'>
    <Img src={WhatsApp}/>
   </a>
   </>
  );
}
export default WhatsappButton;

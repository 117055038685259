import styled from "styled-components";
import Background from "../../assets/background.svg";

export const Container = styled.section` 
height: 100vh;
width: 100%;
display: flex;
flex-direction: row;
background: url(${Background});
background-position: center;
background-size: cover;

@media (max-width: 768px){
height: max-content;
flex-direction: column;
background: transparent;
padding: 60px 0px;
gap: 30px;
}
`;

export const Box = styled.div` 
width: 100%;
height: 100vh;
display:flex;
justify-content: center;
align-items: center;



h1{
width: 85%;
font-size: 2.5em;
color: #fff;
font-weight: 400;
text-align: left;
}

strong{
color: #6B50C5;
}

img{
height: 80%;
width: auto;
transform: scale(1.5);
transition: 1s;
}

@media (max-width: 768px){
height: max-content;

h1{
font-size: 1.5em;
}

img{
    height: 300px;
}
}
`;
import {Container, Box, Content} from './style.js';
import TerminalIcon from '@mui/icons-material/Terminal';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import CategoryIcon from '@mui/icons-material/Category';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
function ActingSection() {
  return (
    <Container id='areas'>
        <h2>Áreas de atuação</h2>
        <Content>
            <Box>
                <TerminalIcon style={{color: "#fff"}} />
                <h3>Desenvolvimento</h3>
                <ul>
                    <li>Desenvolvedor Mobile</li>
                    <li>Desenvolvedor Full-Stack</li>
                    <li>Desenvolvedor Back-end</li>
                    <li>Desenvolvedor Front-end</li>
                    <li>Desenvolvedor Web</li>
                </ul>
            </Box>
            <Box>
                <DataUsageIcon style={{color: "#fff"}} />
                <h3>BI e Dados</h3>
                <ul>
                    <li>Business Intelligence</li>
                    <li>Data Engineer</li>
                    <li>Data Science</li>
                    <li>Analista de Dados</li>
                    <li>Analista de Performance</li>
                </ul>
            </Box>
            <Box>
                <DesignServicesIcon style={{color: "#fff"}} />
                <h3>Design</h3>
                <ul>
                    <li>Product Designer</li>
                    <li>UX Designer</li>
                    <li>UI Designer</li>
                    <li>Web Designer</li>
                    <li>Designer Gráfico</li>
                </ul>
            </Box>
            <Box>
                <CategoryIcon style={{color: "#fff"}} />
                <h3>Produto</h3>
                <ul>
                    <li>Business Development</li>
                    <li>Product Manager</li>
                    <li>Product Owner</li>
                    <li>Consultoria</li>
                    <li>Estratégia</li>
                </ul>
            </Box>
            <Box>
                <AccountTreeIcon style={{color: "#fff"}} />
                <h3>T.I</h3>
                <ul>
                    <li>DevOps</li>
                    <li>Infraestrutura</li>
                    <li>Segurança da Informação</li>
                    <li>Administrador de Sistema</li>
                    <li>Administrador de Base de Dados</li>
                </ul>
            </Box>
        </Content>
        <Content>

            
        </Content>
    </Container>
  );
}
export default ActingSection;

import styled from "styled-components";

export const Container = styled.section` 
height: 30vh;
width: 100%;
display: flex;
flex-direction: row;
justify-content: left;
align-items: center;

.content{
display: flex;
flex-direction: row;
gap: 80px;
padding: 0px 40px;
}

@media (max-width: 768px){
height: max-content;

.content{
flex-direction: column;
width:100%;
gap: 0px;
}
}
`;

export const Box = styled.div` 
width:100%;
height: 30vh;
display:flex;
flex-direction: column;
justify-content: center;
align-items: left;
gap: 10px;

img{
height: auto;
width: 300px;
}

h2{
color: #fff;
}

h4{
color: #fff;
font-weight: 400;
}

h3{
color: #fff;
font-weight: 400;
font-size: 1em;
}

`;


import styled from "styled-components";

export const Container = styled.section` 
height: 100vh;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

h1{
font-size: 3em;
color: #fff;
margin-bottom: 50px;
}

strong{
color: #6B50C5;
}

@media (max-width: 768px){
height: max-content;

h1{
font-size: 2em;
}
}
`;

export const Content = styled.div` 
height: max-content;
width: 80%;
display: flex;
justify-content: center;
align-items: start;
background-color: #1A1B34;
border: 2px solid #6B50C5;
border-radius: 20px;
padding: 80px 30px 80px 30px;

@media (max-width: 768px){
height: max-content;
flex-direction: column;
gap: 50px;

}
`;

export const Box = styled.div` 
width: 100%;
height: auto;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 20px;

svg{
height: 50px;
width: auto;
color: #fff;
background-color:#6B50C5;
padding: 10px; 
border-radius: 5px;
}

h3{
margin: 0;
color: #fff;
}

p{
margin: 0;
color: #fff;
text-align: center;
}
`;
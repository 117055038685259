import {Container, Box} from './style.js';
import Laptop from "../../assets/laptop.png";
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import { useLayoutEffect } from 'react';

function HeroSection() {

  useLayoutEffect (() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to(".laptop",{
      x: 0,
      opacity: "1",
      scale: "1",
      ScrollTrigger:{
        start: "top 0px",
        end: "bottom 500px",
      }
    })

    return () => {
      gsap.killTweensOf(".notebook")
    }
   

  }, [])

  return (
    <Container id='hero'>
        <Box>
            <h1>
                Soluções Ágeis e Eficientes em <strong>Recrutamento e Seleção</strong> de Profissionais de Tecnologia
            </h1>
        </Box>
        <Box>
            <img className='laptop' src={Laptop}></img>
        </Box>
    </Container>
  );
}
export default HeroSection;

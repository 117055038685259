import {Container} from './style.js';
function Cnpj() {
const currentYear = new Date().getFullYear();
  return (
    <Container>
        <h4>47.927.456/0001-65 Rafael Nunes Figueiredo LTDA - Copyright © {currentYear}.</h4>
    </Container>
  );
}
export default Cnpj;

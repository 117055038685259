import {Container, Box} from './style.js';
import Logotipo from '../../assets/logotipo.png';

function Footer() {
  return (
    <Container id='contato'>
        <div className='content'>
            <Box>
                <img src={Logotipo} />
                <h3>Sua Solução em Recrutamento e Seleção de Tecnologia</h3>
            </Box>
            <Box>
                <h2>Contatos</h2>
                <h4>Head de Operações</h4>
                <h4>wilime.borges@zaevotech.com.br</h4>
                <h4>(85) 9 9766-4269</h4>
            </Box>
        </div>
    </Container>
  );
}
export default Footer;

import styled from 'styled-components';

export const Container = styled.section`
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
  padding-bottom: 150px;
  gap: 100px;

  h2 {
    color: #fff;
    font-size: 3em;
    width: 80%;
    text-align: center;
  }

  strong{
  color: #6B50C5;
  }

  @media(max-width: 768px){
  h2{
  font-size: 2em;
  width: 100%;
  }
  }
`;

export const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 15px;
  justify-items: center;

  @media(max-width: 500px){
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`;

export const Box = styled.div`
  width: 100%;
  max-width: 300px;
  height: 200px;
  background-color: #212045;
  margin-top: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  border: 1px solid #6B50C5;
  transition: .3s;
  &:hover{
  transform: scale(.9);
  }


  h3{
  color: #fff;
  }
`;

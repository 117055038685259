import styled from "styled-components";
import Background from "../../assets/background2.svg";

export const Container = styled.section` 
height: 100vh;
width: 100%;
display: flex;
flex-direction: row;
background: url(${Background});
background-position: center;
background-size: cover;

@media (max-width: 768px){
height: max-content;
flex-direction: column;
padding: 100px 0px;
background: transparent;
}
`;

export const Box = styled.div` 
width:100%;
height: 100vh;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: left;

h1{
font-size: 3em;
color: #fff;
text-align: left;
}

p{
width: 85%;
color: #fff;
font-size: 1.1em;
text-align: justify;
}

img{
height: 650px;
width: auto;
}

@media (max-width: 768px){

h1{
font-size: 2em;
}

img{
height: 60%;
}
}
`;
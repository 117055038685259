import './App.css';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection/index';
import IntentionSection from './components/IntentionSection';
import WhoSection from './components/WhoSection';
import ServicesSection from './components/ServicesSection';
import WhySection from './components/WhySection';
import WhatsappButton from './components/WhatsappButton';
import SolutionSection from './components/SolutionSection';
import FormSection from './components/FormSection';
import Footer from './components/Footer';
import Cnpj from './components/Cnpj';
import ProfessionalsSection from './components/ProfessionalsSection';
import ActingSection from './components/ActingSection';

function App() {
  return (
    <>
      <WhatsappButton />
      <Navbar />
      <HeroSection />
      <IntentionSection />
      <WhoSection />
      <ServicesSection />
      <ActingSection />
      <WhySection />
      <ProfessionalsSection />
      <SolutionSection />
      <FormSection />
      <Footer />
      <Cnpj />
    </>
  );
}

export default App;

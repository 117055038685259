import {Container, Box, Content} from './style.js';
function ProfessionalsSection() {
  return (
    <Container>
        <h2>Por que as empresas ainda tem dificuldades em encontrar <strong>profissionais qualificados?</strong></h2>
        <Content>
            <Box>
                <h3>Não tenho recrutamento especializado em TI</h3>
            </Box>
            <Box>
                <h3>Meus gestores tem que ir atrás dos profissionais e não tem tempo pra isso.</h3>
            </Box>
            <Box>
                <h3>Tenho muita volatilidade na equipe e desistências nos processos seletivos.</h3>
            </Box>
            <Box>
                <h3>Publicamos nossas vagas e não recebemos cvs de profissionais de TI.</h3>
            </Box>
            <Box>
                <h3>Meu parceiro de recrutamento de TI não é 100% assertivo nos perfis.</h3>
            </Box>
            <Box>
                <h3>Trabalhamos bem no recrutamento, mas a demanda está insana!!!!</h3>
            </Box>
        </Content>
    </Container>
  );
}
export default ProfessionalsSection;

import styled from "styled-components";

export const Container = styled.section` 
height: 80vh;
width: 100%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
background-position: center;
background-size: cover;

@media (max-width: 768px){
height: max-content;
flex-direction: column;
padding: 60px 0px;
}
`;

export const Box = styled.div` 
width: 450px;
height: auto;
display:flex;
background-color: #1b1b35;
flex-direction: column;
justify-content: start;
align-items: center;
text-align: left;
padding: 50px 50px;
gap: 30px;

h1{
font-size: 3em;
color: #fff;
text-align: left;
}

h3{
color: #6B50C5;
font-size: 1.5em;
}

p{
width: 85%;
color: #fff;
font-size: 1.1em;
}

ul{
display:flex;
flex-direction: column;
gap: 15px;
}

li{
color: #000;
font-size: 1.1em;
}

img{
height: 650px;
width: auto;
}

@media (max-width: 768px){
width: 80%;
padding: 40px 30px;
}

li{
color: #000;
font-size: .9em;
}

`;
import styled from "styled-components";
import Background from "../../assets/background3.svg";

export const Container = styled.section` 
height: 100vh;
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-around;
align-items: center;
background: url(${Background});
background-position: center;
background-size: cover;

@media (max-width: 768px){
height: max-content;
flex-direction: column;
gap: 30px;
}
`;

export const Box = styled.div` 
width: 300px;
height: 350px;
display:flex;
border: 2px solid #6B50C5;
border-radius: 30px;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
background-color: #1A1B34;
padding: 20px;

p{
color: #fff;
font-size: 1.5em;
font-weight: 600;
}
`;
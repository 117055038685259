import styled from "styled-components";
import Background from "../../assets/background2.svg";

export const Container = styled.section` 
height: 100vh;
width: 100%;
display: flex;
flex-direction: row;
background: url(${Background});
background-position: center;
background-size: cover;


@media (max-width: 768px){
flex-direction: column;
gap: 50px;
}
`;

export const Box = styled.div` 
width:100%;
height: 100vh;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;


h2{
width: 90%;
font-size: 3.5em;
color: #fff;
font-weight: 600;
text-align: left;
}

strong{
color: #6B50C5;
}

img{
height: auto;
width: 65%;
}

@media (max-width: 768px){
height: max-content;

h2{
width: 90%;
font-size: 2.5em;
color: #fff;
font-weight: 600;
text-align: left;
}
}
`;
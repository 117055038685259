import {Container, Box, Content} from './style.js';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
function IntentionSection() {
  return (
    <Container id='intention'>
        <h1>Nosso <strong>Propósito</strong></h1>
        <Content>
            <Box>
                <InsertDriveFileIcon />
                <h3>Desburocratizar o recrutamento</h3>
                <p>Simplificamos o processo de contratação 
                para que você possa focar no seu negócio. 
                Com nossa expertise e abordagem estratégica, 
                cuidamos de todas as etapas, desde a triagem 
                inicial até a seleção final, garantindo que 
                você receba os melhores candidatos de maneira 
                rápida e eficiente.</p>
            </Box>
            <Box>
                <VerifiedUserIcon />
                <h3>Qualidade e Agilidade</h3>
                <p>Entregamos profissionais qualificados
                 em até 10 dias corridos. Nosso processo 
                 de seleção é ágil e eficiente, permitindo
                 identificar e apresentar os melhores candidatos 
                 em um prazo extremamente curto.</p>
            </Box>
            <Box>
                <LocalLibraryIcon />
                <h3>Tranquilidade para o cliente</h3>
                <p>Garantimos que todo o processo será 
                realizado de forma eficiente e sem preocupações.
                Desde o momento em que iniciamos a busca pelos 
                candidatos até a finalização da contratação, nossa
                equipe de especialistas cuida de cada detalhe com 
                precisão e dedicação.</p>
            </Box>
        </Content>
    </Container>
  );
}
export default IntentionSection;

import {Container, Box} from './style.js';
function WhySection() {
  return (
    <Container>
        <Box style={{backgroundColor: "#fff"}}>
          <h3>Por que escolher a Zaevo?</h3>
          <ul>
            <li><strong>Agilidade:</strong> Contratação em até 10 dias corridos</li>
            <li><strong>Precisão:</strong> Seleção criteriosa para garantir o melhor profissional para a vaga</li>
            <li><strong>Experiência:</strong> Equipe especializada com ampla experiência em recrutamento de tecnologia</li>
            <li><strong>Satisfação Garantida:</strong> Processo descomplicado e eficiente, sem preocupações para o cliente</li>
          </ul>
        </Box>
        <Box style={{border: "1px solid #6B50C5"}}>
          <h3>Nossos Resultados</h3>
          <ul>
            <li style={{color: "#fff"}}>Taxa de satisfação dos clientes<strong> (NPS): 88,5%</strong></li>
            <li style={{color: "#fff"}}>Mais de 200 profissionais de tecnologia contratados em 2024</li>
            <li style={{color: "#fff"}}>Tempo médio de preenchimento da vaga:<strong> 7 dias corridos</strong></li>
          </ul>
        </Box>
    </Container>
  );
}
export default WhySection;

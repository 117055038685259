import {Container, Box} from './style.js';
import Person from "../../assets/person.png"

function SolutionSection() {
  return (
    <Container>
        <Box>
            <h2>Sua Solução em Recrutamento e Seleção de Tecnologia</h2>
        </Box>
        <Box>
            <img src={Person} />
        </Box>
    </Container>
  );
}
export default SolutionSection;
